<template>
  <div class="table-of-contents">
    <div class="table-of-contents__headline">
      {{ headline }}
    </div>

    <div class="table-of-contents__menu">
      <a
        :href="`#${anchor}`"
        :class="[
          'table-of-contents__link',
          `table-of-contents__link--level-${anchorlevel}`,
        ]"
        v-for="({ anchor, anchorlevel }, index) in anchors"
        :key="index"
        @click.prevent="scrollTo(anchor)"
      >
        {{ anchor }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    anchors: {
      type: Array,
      default: null,
    },

    headline: {
      type: String,
      default: "",
    },
  },

  methods: {
    scrollTo(anchor) {
      const element = document.querySelector(`[name="${anchor}"]`);
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.table-of-contents {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background: #000;
  color: #fff;
  z-index: 10;
  transition: all 0.2s ease-in-out;
  width: 3.2rem;

  @include mq-tablet {
    width: 4rem;
  }
}

.table-of-contents__headline {
  @extend %text;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  height: 100%;
  width: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s ease-in-out 0.1s;
  line-height: 1;
  pointer-events: none;

  @include mq-tablet {
    width: 4rem;
  }
}

.table-of-contents__menu {
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: all 0.1s ease-in-out 0s;
  top: 0;
  pointer-events: none;
  width: 18rem;
  padding: 2rem;
  height: 100%;
  overflow: auto;

  a {
    @extend %text-small;
    color: #fff;
    text-decoration: none;
    transition: all 0.1s ease-in-out 0s;
    display: block;
    margin-bottom: 0.75em;

    &:hover {
      color: var(--category-color);
    }
  }
}

.table-of-contents__link--level-2 {
  padding-left: 0.5em;
}

.table-of-contents__link--level-3 {
  padding-left: 1em;
}

.table-of-contents__link--level-4 {
  padding-left: 1.5em;
}

.table-of-contents:hover {
  width: 18rem;

  .table-of-contents__headline {
    opacity: 0;
    transition: all 0.1s ease-in-out 0s;
  }

  .table-of-contents__menu {
    opacity: 1;
    transition: all 0.1s ease-in-out 0.2s;
    pointer-events: all;
  }
}
</style>
