<template>
  <component
    :is="href ? 'a' : 'div'"
    :href="href"
    class="base-button"
    :class="{ 'base-button--wide': fullWidth }"
    @click="$emit('btn-click')"
    @keyup.enter="$emit('btn-click')"
    tabindex="0"
  >
    <div class="base-button__wrap">
      <div v-if="$slots.icon" class="base-button__icon">
        <slot name="icon" />
      </div>

      <div class="base-button__content">
        <slot />
      </div>
    </div>
  </component>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: null,
    },
    fullWidth: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.base-button {
  @extend %text;
  display: inline-block;
  background: #000;
  background: var(--category-color);
  cursor: pointer;
  text-decoration: none;
  transition: all 0.15s ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  &--wide {
    width: 100%;
  }
}

.base-button__wrap {
  display: flex;
  align-items: center;
  padding: 0.5em 1.2em;
  color: #fff;
}

.base-button__icon {
  width: 1.6rem;
  margin-right: 1rem;
  transition: transform 300ms;

  .base-button--icon-rotate & {
    transform: rotate(45deg);
  }

  svg {
    display: block;
    width: 100%;
  }
}
</style>
