export default {
  props: {
    lang: {
      type: String,
      default: "de",
    },
  },
  computed: {
    homeUrl() {
      if (this.lang !== "de") {
        return "/" + this.lang + "/";
      }

      return "/";
    },
  },
};
