<template>
  <div class="text-content" :class="{ 'text-content--narrow': narrow }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    narrow: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.text-content {
  @extend %scoped-content;
  margin: 4rem 0;

  &--narrow {
    margin: 1rem 0;
  }
}
</style>
