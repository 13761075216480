<template>
  <div
    :class="[
      'page-layout',
      { 'page-layout--has-toc': anchors && anchors.length },
    ]"
  >
    <a :href="homeUrl" class="page-layout__logo">
      <BaseLogo />
    </a>

    <TableOfContents
      v-if="anchors && anchors.length"
      :anchors="anchors"
      :headline="toc"
    />

    <div class="page-layout__container scroll-reveal" v-scroll-reveal>
      <h1 v-if="headline" class="page-layout__headline">
        {{ headline }}
      </h1>

      <div class="page-layout__excerpt" v-if="$slots.excerpt">
        <slot name="excerpt" />
      </div>

      <div class="page-layout__content">
        <slot />
      </div>
    </div>

    <div class="page-layout__topmeta">
      <slot name="topmenu" />
    </div>
  </div>
</template>

<script>
import homeUrl from "./mixins/homeUrl";

export default {
  mixins: [homeUrl],
  name: "PageLayout",

  props: {
    headline: {
      type: String,
    },

    anchors: {
      type: Array,
      default: null,
    },

    toc: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.page-layout {
  padding: 35vw 3rem 3rem 3rem;

  @include mq-tablet {
    padding: 15vw 3rem 3rem 3rem;
  }
}

.page-layout__logo {
  position: fixed;
  top: 3rem;
  left: 3rem;
  width: 12rem;
  display: flex;
  z-index: 10;

  @include mq-tablet {
    left: 6rem;
  }
}

.page-layout__container {
  margin: 0 auto;

  @include mq-tablet {
    width: 60vw;
  }
}

.page-layout__headline {
  @extend %h0;
  margin: 0 0 0.5em 0;
}

.page-layout__excerpt {
  @extend %text-excerpt-big;
  margin-bottom: 3em;
}

.page-layout__content {
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.page-layout__topmeta {
  position: absolute;
  z-index: 100;
  top: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  color: #000;
  position: fixed;
}

.page-layout__burger {
  padding: 1rem;
  color: inherit;
  cursor: pointer;
  width: 5rem;
  height: 5rem;
  transition: all 0.15s ease-in-out;

  &:hover {
    opacity: 0.6;
  }

  @include mq-tablet {
    width: 4rem;
    height: 4rem;
  }
}

.page-layout__social {
  padding-top: 5rem;
}

.page-layout--has-toc {
  padding-left: 5rem;

  .page-layout__logo {
    left: 5rem;

    @include mq-tablet {
      left: 6rem;
    }
  }
}
</style>
