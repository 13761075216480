<template>
  <a :href="href" class="icon-link">
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.icon-link {
  text-decoration: none;
  display: block;
  color: #000;
  width: 2.65rem;
}
</style>
