<template>
  <div class="team-member" style="--category-color: #000">
    <div class="team-member__head">
      <div class="team-member__name">{{ firstname }} {{ lastname }}</div>

      <div class="team-member__image">
        <slot name="image" />
      </div>

      <div class="team-member__block" :style="{ background: color }" />
    </div>

    <div class="team-member__body">
      <div class="team-member__body-name">{{ firstname }} {{ lastname }}</div>

      <div class="team-member__bio">
        <slot />
      </div>

      <div class="team-member__social-desktop">
        <slot name="social-desktop" />
      </div>

      <div class="team-member__social-mobile">
        <slot name="social-mobile" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    firstname: {
      type: String,
      default: "",
    },

    lastname: {
      type: String,
      default: "",
    },

    color: {
      type: String,
      default: "#000",
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.team-member {
  margin: 9.33rem 0;

  @include mq-tablet {
    margin: 14rem 0;
  }
}

.team-member__head {
  position: relative;
}

.team-member__name {
  @extend %h1;
  padding: 0.5em 0;
}

.team-member__body-name {
  @extend %h2;
  padding-top: 1em;
}

.team-member__image {
  position: relative;
  z-index: 1;
}

.team-member__block {
  position: absolute;
  top: 0;
  height: 100%;
  width: 30rem;
}

.team-member__bio {
  @extend %text;
  margin: 1.5em 0;
}

.team-member__social-mobile {
  display: flex;
  align-items: center;
  margin-left: -1.25rem;

  > * {
    margin-left: 1.25rem;
  }

  @include mq-tablet {
    display: none;
  }
}

.team-member__social-desktop {
  align-items: center;
  margin-left: -1.25rem;
  display: none;

  > * {
    margin-left: 1.25rem;
  }

  @include mq-tablet {
    display: flex;
  }
}

.team-member--right {
  margin-left: -3rem;
  margin-right: -3rem;

  .team-member__head {
    padding-left: 9.7rem;
    padding-top: 8.7rem;
  }

  .team-member__body {
    padding: 0 3rem;
  }

  .team-member__name {
    display: none;
  }

  .team-member__block {
    left: 0;
  }

  @include mq-tablet {
    margin-left: -5rem;
    margin-right: 0rem;

    .team-member__head {
      padding-left: 15rem;
      padding-top: 0rem;
    }

    .team-member__body {
      padding-left: 15rem;
    }

    .team-member__name {
      display: block;
      padding-left: 18.3rem;
    }

    .team-member__body-name {
      display: none;
    }
  }
}

.team-member--left {
  margin-left: -3rem;
  margin-right: -3rem;

  .team-member__head {
    padding-right: 9.7rem;
    padding-top: 8.7rem;
  }

  .team-member__body {
    padding: 0 3rem;
  }

  .team-member__name {
    display: none;
  }

  .team-member__block {
    right: 0;
  }

  @include mq-tablet {
    margin-right: -5rem;
    margin-left: 0rem;

    .team-member__head {
      padding-right: 15rem;
      padding-top: 0rem;
    }

    .team-member__body {
      padding-right: 15rem;
    }

    .team-member__name {
      display: block;
      padding-right: 18.3rem;
    }

    .team-member__body-name {
      display: none;
    }
  }
}

.team-member:nth-child(odd) {
  @extend .team-member--right;
}

.team-member:nth-child(even) {
  @extend .team-member--left;
}
</style>
