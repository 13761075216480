<template>
  <span class="tag-inline">
    <span class="tag-inline__box" :style="{ background: color }"></span>

    <span class="tag-inline__tag">
      {{ tag }}
    </span>
  </span>
</template>

<script>
export default {
  name: "TagInline",

  props: {
    tag: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      default: "#ffffff",
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.tag-inline {
  @extend %text-small;

  display: flex;
  align-items: center;
}

.tag-inline__box {
  width: 0.5em;
  height: 0.5em;
  display: block;
  margin-right: 0.5em;
}

.tag-inline__tag {
  @extend %text-small;
}
</style>
