<template>
  <div class="search-results">
    <h1 class="search-results__headline" v-html="headline" />

    <div class="search-results__results">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headline: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.search-results__headline {
  @extend %h0;
  margin: 0 0 1.5em 0;

  span {
    color: var(--category-color);
  }
}

.search-results__results {
  padding-bottom: 10vw;

  > :not(:last-child) {
    margin-bottom: 1em;
  }
}
</style>
