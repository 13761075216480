<template>
  <a :href="href" class="search-result">
    <span class="search-result__inner">
      <slot />
    </span>
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.search-result {
  @extend %h2;
  color: #000;
  text-decoration: none;
  display: block;
}

.search-result__inner {
  display: block;
  transition: all 0.15s ease-in-out;
}

.search-result:hover .search-result__inner {
  transform: translateX(0.5rem);
}
</style>
