<template>
  <div class="content-image">
    <a v-if="href" :href="href">
      <slot />
    </a>

    <slot v-else />

    <div v-if="caption" class="content-image__caption">
      {{ caption }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    caption: {
      type: String,
      default: null,
    },

    href: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.content-image {
  display: block;
  margin: 4rem 0;
}

.content-image__caption {
  @extend %text-small;
  padding-top: 1em;
}
</style>
