import anime from "animejs/lib/anime.es.js";

export default ({ betterplace, lab }) => {
  const tl = anime.timeline({
    easing: "easeOutQuart",
  });

  tl.add({
    targets: lab,
    translateX: 165,
    duration: 500,
  })
    .add({
      targets: lab,
      translateY: -89,
      duration: 500,
    })
    .add(
      {
        targets: betterplace,
        translateY: 115,
        duration: 500,
      },
      500
    );
};
