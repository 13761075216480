<template>
  <div>
    <div
      :class="[
        'blog-layout',
        { 'blog-layout--has-toc': anchors && anchors.length },
      ]"
    >
      <a :href="homeUrl" class="blog-layout__logo">
        <BaseLogo />
      </a>

      <nav role="navigation" class="blog-layout__topmeta">
        <slot name="topmenu" />
      </nav>

      <TableOfContents
        v-if="anchors && anchors.length"
        :anchors="anchors"
        :headline="toc"
      />

      <main id="maincontent" class="scroll-reveal" v-scroll-reveal>
        <header class="blog-layout__head">
          <div class="blog-layout__head-container">
            <h1 class="blog-layout__headline">
              {{ headline }}
            </h1>

            <div class="blog-layout__image">
              <slot name="image" />
            </div>
          </div>

          <div class="blog-layout__block" />
        </header>

        <div class="blog-layout__container">
          <div class="blog-layout__content-container">
            <aside class="blog-layout__meta">
              <slot name="meta" />
            </aside>

            <!--div class="blog-layout__excerpt">
            <slot name="excerpt" />
          </div-->

            <div class="blog-layout__content">
              <slot name="content" />
            </div>

            <div v-if="!!$slots.newsletter" class="blog-layout__newsletter">
              <slot name="newsletter" />
            </div>

            <div class="blog-layout__podcast">
              <slot name="podcast" />
            </div>

            <div class="blog-layout__social">
              <slot name="social" />
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import homeUrl from "./mixins/homeUrl";

export default {
  mixins: [homeUrl],
  name: "BlogLayout",

  props: {
    headline: {
      type: String,
    },

    anchors: {
      type: Array,
      default: null,
    },

    toc: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.blog-layout {
  padding: 30vw 3rem 3rem 3rem;

  @include mq-tablet {
    padding: 15vw 3rem 3rem 3rem;
  }
}

.blog-layout__logo {
  position: fixed;
  top: 3rem;
  left: 3rem;
  width: 12rem;
  display: flex;
  z-index: 10;

  @include mq-tablet {
    left: 6rem;
  }
}

.blog-layout__head {
  position: relative;
  margin: 0 -3rem;
}

.blog-layout__head-container {
  margin: 0 auto;
  padding: 0 3rem;

  @include mq-tablet {
    padding: 0;
    width: 65vw;
  }
}

.blog-layout__headline {
  @extend %h0;
  position: relative;
  margin: 0;
  padding: 1em 0;
  z-index: 1;
}

.blog-layout__image {
  position: relative;
  z-index: 1;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.blog-layout__block {
  position: absolute;
  top: 0;
  height: 100%;
  width: 30vw;
  background: var(--category-color);
}

.blog-layout__container {
  margin: 0 auto;

  @include mq-tablet {
    width: 65vw;
  }
}

.blog-layout__meta {
  @extend %text;
  padding: 3em 0;
  margin-top: -2em;

  > * {
    margin-top: 2em;
  }

  @include mq-tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tag-box-list {
      justify-content: flex-end;
    }
  }
}

.blog-layout__excerpt {
  @extend %text-excerpt;

  padding: 0 0 3em 0;
}

.blog-layout__content-container {
  @include mq-tablet {
    padding: 0 5rem;
  }
}

.blog-layout__content {
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.blog-layout__topmeta {
  position: absolute;
  z-index: 100;
  top: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  color: #000;
  position: fixed;
}

.blog-layout__burger {
  padding: 1rem;
  color: inherit;
  cursor: pointer;
  width: 5rem;
  height: 5rem;
  transition: all 0.15s ease-in-out;

  &:hover {
    opacity: 0.6;
  }

  @include mq-tablet {
    width: 4rem;
    height: 4rem;
  }
}

.blog-layout__newsletter {
  padding-top: 5rem;
}

.blog-layout__podcast {
  padding-top: 5rem;

  &:empty {
    display: none;
  }
}

.blog-layout__social {
  padding-top: 5rem;
}

.blog-layout--has-toc {
  padding-left: 5rem;

  .blog-layout__logo {
    left: 5rem;

    @include mq-tablet {
      left: 6rem;
    }
  }
}
</style>
