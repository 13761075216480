/**
 * trap focus in active overlay
 * - for tab navigation accessibility
 */
export default () => {
  document.addEventListener(
    "focus",
    function (event) {
      focusSet(document.querySelector(".search-overlay"), event);
      focusSet(document.querySelector(".navigation-overlay"), event);
    },
    true
  );
};

/**
 *
 * @param {HTMLElement} el
 * @param {FocusEvent} event
 */
function focusSet(el, event) {
  if (el !== null && !el.contains(event.target)) {
    event.stopPropagation();
    el.focus();
  }
}
