<template>
  <div class="contact-person">
    <h2 class="contact-person__headline">
      {{ headline }}
    </h2>

    <div class="contact-person__image">
      <slot />
    </div>

    <div class="contact-person__content" v-html="text" />

    <div class="contact-person__info">
      <a :href="`mailto:${email}`" class="contact-person__email">
        → {{ email }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactPerson",

  props: {
    headline: {
      type: String,
    },

    text: {
      type: String,
    },

    email: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.contact-person {
  margin: 4rem 0;
}

.contact-person__headline {
  @extend %h2;
  margin-bottom: 0.75em;
}

.contact-person__image {
  margin: 0 0 3rem 0;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.contact-person__content {
  @extend %scoped-content;
}

.contact-person__info {
  display: flex;
}

.contact-person__email {
  @extend %text;

  font-weight: 700;
  text-decoration: none;
  border-bottom: 2px solid;
  color: inherit;
  display: block;
  border-color: #44c4af;
  border-color: var(--category-color);
}
</style>
