import Vue from "vue";
import VueScrollReveal from "vue-scroll-reveal";
import "./components";
import "./app.scss";

Vue.config.productionTip = false;

Vue.use(VueScrollReveal, {
  class: "v-scroll-reveal",
  distance: "20px",
  duration: 800,
  easing: "cubic-bezier(0.5, 0, 0, 1)",
  viewOffset: {
    top: 80,
    bottom: 80,
  },
});

new Vue({
  el: "#app",
  delimiters: ["${", "}"],
});
