<template>
  <div class="tag-inline-list">
    <slot />
  </div>
</template>

<script>
export default {
  name: "TagInlineList",
};
</script>

<style lang="scss">
.tag-inline-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -1rem;

  > * {
    margin: 0 0 0 1rem;
  }
}
</style>
