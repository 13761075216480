import anime from "animejs/lib/anime.es.js";

export default ({ betterplace, lab }) => {
  const tl = anime.timeline({
    easing: "easeOutQuart",
  });

  tl.add({
    targets: lab,
    translateX: -93,
    duration: 500,
  }).add({
    targets: betterplace,
    translateX: 70,
    duration: 500,
  });
};
