<template>
  <div>
    <div class="tag-box-list">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "TagBoxList",
};
</script>

<style lang="scss">
.tag-box-list {
  display: flex;
  flex-wrap: wrap;
  margin: -1vw 0 0 -1vw;

  > * {
    margin: 1vw 0 0 1vw;
  }
}
</style>
