<template>
  <div class="toggle-content">
    <BaseButton
      fullWidth="1"
      @btn-click="toggle"
      :class="{ 'base-button--icon-rotate': toggleOpen }"
    >
      <template #icon>
        <PlusIcon></PlusIcon>
      </template>

      <template #default>
        {{ heading }}
      </template>
    </BaseButton>

    <div v-if="toggleOpen" class="toggle-content__text">
      <TextContent narrow="1">
        <slot />
      </TextContent>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton";

export default {
  name: "ToggleContent",
  components: { BaseButton },
  props: {
    heading: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      toggleOpen: false,
    };
  },
  methods: {
    toggle() {
      this.toggleOpen = !this.toggleOpen;
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.toggle-content {
  @extend %scoped-content;
  margin: 4rem 0;
}
</style>
