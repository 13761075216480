<template>
  <article>
    <a :href="url" class="base-teaser">
      <div class="base-teaser__image">
        <slot />
      </div>

      <div class="base-teaser__tags">
        <TagInlineList>
          <TagInline
            v-for="{ categoryColorHex, title } in [...categories, ...services]"
            :key="title"
            :color="categoryColorHex"
            :tag="title"
          />
        </TagInlineList>
      </div>

      <header>
        <h3 class="base-teaser__title">
          {{ title }}
        </h3>
      </header>
      <p class="base-teaser__excerpt" v-if="excerpt">
        {{ excerpt }}
      </p>
    </a>
  </article>
</template>

<script>
export default {
  name: "BaseTeaser",

  props: {
    title: {
      type: String,
      required: true,
    },

    excerpt: {
      type: String,
      required: true,
    },

    url: {
      type: String,
      required: true,
    },

    categories: {
      type: Array,
      required: true,
    },

    services: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.base-teaser {
  @extend %text;

  display: block;
  color: #fff;
  text-decoration: none;
}

.base-teaser__image img {
  display: block;
  width: 100%;
  height: auto;
}

.base-teaser__tags {
  margin: 1.2em 0 0.8em;
}

.base-teaser__title {
  @extend %text;
}

.base-teaser__excerpt {
  @extend %text-small;
  margin-top: -1rem;
}
</style>
