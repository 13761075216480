<template>
  <a :href="url" :class="['tag-box', `tag-box--${type}`]">
    <span class="tag-box__box" :style="{ background: color }"></span>

    <span class="tag-box__tag">
      {{ tag }}
    </span>
  </a>
</template>

<script>
export default {
  name: "TagBox",

  props: {
    tag: {
      type: String,
      required: true,
    },

    url: {
      type: String,
      required: true,
    },

    color: {
      type: String,
      default: "#ffffff",
    },

    type: {
      type: String,
      default: "big",
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.tag-box {
  @extend %text;

  display: inline-flex;
  align-items: center;
  border: 3px solid #000;
  background-color: #000;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  transition: all 0.15s ease-in-out;
}

.tag-box__box {
  width: 0.5em;
  height: 0.5em;
  display: block;
  margin-right: 0.6em;
}

.tag-box:hover {
  background: transparent;
  color: #000;
}

.tag-box--big {
  @extend %text;

  padding: 0.3em 0.8em;
}

.tag-box--small {
  @extend %text-small;

  padding: 0.3em 0.5em;
}
</style>
