<template>
  <div class="newsletter-module">
    <div class="newsletter-module__content">
      <h3 class="newsletter-module__title">{{ title }}</h3>
      <div class="newsletter-module__text">
        <slot name="text" />
      </div>
      <BaseButton :href="url" target="_blank">
        <template #icon>
          <OutlineMailIcon />
        </template>

        <template #default>
          {{ cta }}
        </template>
      </BaseButton>
    </div>
    <div class="newsletter-module__image">
      <slot name="image" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    url: {
      type: String,
      required: true,
    },

    cta: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.newsletter-module {
  @include mq-tablet {
    display: flex;
  }
}

.newsletter-module__content {
  color: #fff;
  background: #000;
  padding: 5rem 3rem;

  @include mq-tablet {
    padding: 7rem 3rem;
    flex: 0 0 50%;
    width: 50%;
  }
}

.newsletter-module__image {
  position: relative;

  @include mq-tablet {
    flex: 0 0 50%;
    width: 50%;
    padding-bottom: 0;
  }

  img {
    width: 100%;

    @include mq-tablet {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      object-fit: cover;
    }
  }
}

.newsletter-module__title {
  @extend %h2;
  margin: 0 0 0.5em 0;
}

.newsletter-module__text {
  @extend %text;
  margin: 0 0 1.5em 0;
}
</style>
