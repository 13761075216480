<template>
  <div class="front-page">
    <h1 class="front-page__headline">
      {{ headline }}
    </h1>

    <h2 class="front-page__subline">
      <slot name="subline" />
    </h2>

    <div class="front-page__text">
      <slot name="text" />
    </div>

    <div class="front-page__categories">
      <slot name="categories" />
    </div>

    <div class="front-page__content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FrontPage",

  props: {
    headline: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.front-page__headline {
  @extend %h1;
  margin-top: 0;
  margin-bottom: 0.5em;
}

.front-page__subline {
  @extend %h3;
  margin-top: 0;
  margin-bottom: 1em;
}

.front-page__text {
  @extend %text-excerpt;
}

.front-page__content {
  padding: 6rem 0 0 0;
}
</style>
