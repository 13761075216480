<template>
  <div class="social-bar">
    <a
      v-if="facebook"
      :href="facebook"
      class="social-bar__link"
      target="_blank"
    >
      <FacebookIcon />
    </a>

    <a v-if="twitter" :href="twitter" class="social-bar__link" target="_blank">
      <TwitterIcon />
    </a>

    <a
      v-if="instagram"
      :href="instagram"
      class="social-bar__link"
      target="_blank"
    >
      <InstagramIcon />
    </a>

    <a v-if="youtube" :href="youtube" class="social-bar__link" target="_blank">
      <YoutubeIcon />
    </a>

    <a
      v-if="linkedin"
      :href="linkedin"
      class="social-bar__link"
      target="_blank"
    >
      <LinkedinIcon />
    </a>

    <a
      v-if="newsletter"
      :href="newsletter"
      class="social-bar__link"
      target="_blank"
    >
      <MailIcon />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    facebook: {
      type: String,
      default: null,
    },

    twitter: {
      type: String,
      default: null,
    },

    instagram: {
      type: String,
      default: null,
    },

    youtube: {
      type: String,
      default: null,
    },

    linkedin: {
      type: String,
      default: null,
    },

    newsletter: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.social-bar {
  display: flex;
  margin-left: -1.4rem;
  align-items: center;
}

.social-bar__link {
  display: block;
  width: 1.9rem;
  margin-left: 1.4rem;
  color: #000;

  svg {
    width: 100%;
    display: block;
    transition: all 0.25s ease-in-out;
  }

  &:hover svg {
    transform: translateY(-0.4rem);
  }
}
</style>
