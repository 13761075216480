<template>
  <div class="split-layout">
    <div class="split-layout__brand" />

    <section class="split-layout__content">
      <a :href="homeUrl" class="split-layout__logo">
        <BaseLogo :color="color" />
      </a>

      <main id="maincontent" class="scroll-reveal" v-scroll-reveal>
        <!-- has no headline on frontPage, as frontPage has its own h1 setup -->
        <h1 v-if="headline" class="split-layout__headline">
          {{ headline }}
        </h1>

        <slot name="content" />

        <div class="split-layout__social">
          <slot name="social" />

          <div class="split-layout__credit">
            Site by
            <a
              href="https://newnow.cool"
              title="New Now Website"
              target="_blank"
            >
              New Now
            </a>
          </div>
        </div>
      </main>
    </section>

    <aside class="split-layout__aside">
      <div class="scroll-reveal" v-scroll-reveal>
        <slot name="aside" />
      </div>
    </aside>

    <nav role="navigation" class="split-layout__meta">
      <slot name="topmenu" />
    </nav>
  </div>
</template>

<script>
import homeUrl from "./mixins/homeUrl";

export default {
  mixins: [homeUrl],
  name: "SplitLayout",
  props: {
    headline: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.split-layout {
  @extend %h1;
}

.split-layout__brand {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 1rem;
  background-color: var(--category-color);

  @include mq-tablet {
    position: absolute;
    width: 2rem;
  }
}

.split-layout__content {
  position: relative;
  padding: 30vh 3rem 3rem 3rem;

  @include mq-tablet {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50vw;
    padding: 40vh 5rem 3rem 6rem;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
  }
}

.split-layout__aside {
  position: relative;
  background-color: #000;
  padding: 3rem;

  @include mq-tablet {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50vw;
    padding: 8rem 5rem 5rem;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
  }
}

.split-layout__logo {
  position: absolute;
  top: 3rem;
  width: 12rem;
  display: flex;
}

.split-layout__meta {
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  color: #000;

  @include mq-tablet {
    color: #fff;
    position: fixed;
  }
}

.split-layout__burger {
  padding: 1rem;
  color: inherit;
  cursor: pointer;
  width: 5rem;
  height: 5rem;
  transition: all 0.15s ease-in-out;

  &:hover {
    opacity: 0.6;
  }

  @include mq-tablet {
    width: 4rem;
    height: 4rem;

    &:hover {
      opacity: 0.7;
    }
  }
}

.split-layout__headline {
  @extend %h1;

  margin: 0 0 4rem 0;
}

.split-layout__social {
  padding-top: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.split-layout__credit {
  @extend %text-small;

  a {
    color: inherit;
  }
}
</style>
