<template>
  <div
    class="search-input"
    contenteditable="true"
    @input="input"
    @keydown.enter="$refs.input.blur()"
    ref="input"
    :placeholder="placeholder"
    tabindex="0"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      default: null,
    },
  },

  methods: {
    input(e) {
      this.$emit("input", e.target.innerHTML);
    },

    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.search-input {
  @extend %h1;
  outline: none;
  color: inherit;
  border-bottom: 0.05em solid currentColor;
  transition: opacity 0.15s ease-in-out;

  &:empty {
    opacity: 0.5;
  }

  &:empty:before {
    content: attr(placeholder);
    display: block; /* For Firefox */
    pointer-events: none;
  }
}
</style>
