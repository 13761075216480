<template>
  <img class="responsive-img" :sizes="sizes" v-bind="attrs" />
</template>

<script>
import debounce from "lodash.debounce";

export default {
  inheritAttrs: false,

  data() {
    return {
      width: 0,
    };
  },

  computed: {
    attrs() {
      return this.width ? this.$attrs : null;
    },

    sizes() {
      return `${this.width}px`;
    },
  },

  methods: {
    updateWidth() {
      this.width = Math.ceil(this.$el.getBoundingClientRect().width);
    },

    repeatingUpdate() {
      this.updateWidth();

      if (this.width > 0) return;

      const interval = setInterval(() => {
        this.updateWidth();
        if (this.width > 0) clearInterval(interval);
      }, 200);
    },
  },

  mounted() {
    this.repeatingUpdate();

    this.debouncedUpdateWidth = debounce(this.updateWidth, 250);

    window.addEventListener("resize", this.debouncedUpdateWidth);
  },

  destroyed() {
    window.removeEventListener("resize", this.debouncedUpdateWidth);
  },
};
</script>

<style lang="scss">
.responsive-img {
  width: 100%;
  display: block;
}
</style>
