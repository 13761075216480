<template>
  <div :class="['image-text', `image-text--orientation-${orientation}`]">
    <div class="image-text__image">
      <slot name="image" />

      <div v-if="caption" class="image-text__caption">
        {{ caption }}
      </div>
    </div>

    <div class="image-text__text">
      <TextContent>
        <slot name="text" />
      </TextContent>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageText",

  props: {
    caption: {
      type: String,
      default: null,
    },

    orientation: {
      type: String,
      default: "left",
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.image-text {
  @extend %text;
  clear: both;
  margin: 4rem 0;
}

.image-text__image {
  margin-bottom: 1em;
}

.image-text__caption {
  @extend %text-small;
  padding-top: 1em;
}

.image-text--orientation-left .image-text__image {
  @include mq-tablet {
    width: 40%;
    margin-left: -5rem;
    margin-right: 3rem;
    float: left;
  }
}

.image-text--orientation-right .image-text__image {
  @include mq-tablet {
    width: 40%;
    margin-right: -5rem;
    margin-left: 3rem;
    float: right;
  }
}

// special case
.split-layout__content {
  .image-text--orientation-right .image-text__image {
    @include mq-tablet {
      margin-right: 0;
    }
  }

  .image-text--orientation-left .image-text__image {
    @include mq-tablet {
      margin-left: 0;
    }
  }
}
</style>
