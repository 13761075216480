<template>
  <div>
    <div class="pixel-linewrapper">
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-white"></div>
      <div class="pixel-white"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
      <div class="pixel-black"></div>
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
    </div>
    <div class="pixel-linewrapper">
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-white"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
    </div>
    <div class="pixel-linewrapper">
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-white"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
      <div class="pixel-black"></div>
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
    </div>
    <div class="pixel-linewrapper">
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-white"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
    </div>
    <div class="pixel-linewrapper">
      <div class="pixel-black"></div>
      <div class="pixel-black"></div>
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
      <div class="pixel-black"></div>
      <div class="pixel-black"></div>
      <div class="pixel-black"></div>
      <div class="pixel-white"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PixelGame",
};
</script>

<style>
.pixel-linewrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 20vh;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pixel-black {
  width: 100%;
  height: 100%;
  background-color: #000;
  -webkit-filter: hue-rotate(0deg);
  filter: hue-rotate(0deg);
  box-shadow: inset 0 0 0 3px #000;
  -webkit-transition: all 4s ease;
  transition: all 4s ease;
  cursor: pointer;
}

.pixel-black:hover,
.pixel-black:active {
  background-color: #ff9800;
  -webkit-filter: hue-rotate(360deg);
  filter: hue-rotate(360deg);
  -webkit-transition: all 0s ease;
  transition: all 0s ease;
}

.pixel-black:focus {
  background-color: #ff9800;
  -webkit-filter: hue-rotate(360deg);
  filter: hue-rotate(360deg);
  -webkit-transition: all 0s ease;
  transition: all 0s ease;
}

.pixel-white {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: inset 0 0 0 3px #000;
  -webkit-transition: background-color 4000ms linear;
  transition: background-color 4000ms linear;
  cursor: pointer;
}

.pixel-white:hover,
.pixel-white:active {
  background-color: #000;
  -webkit-transition: background-color 00ms linear;
  transition: background-color 00ms linear;
}
</style>
