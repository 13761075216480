<template>
  <transition name="fade">
    <div
      class="search-overlay"
      v-if="show"
      @keyup.esc="close"
      tabindex="-1"
      role="dialog"
    >
      <div class="search-overlay__content">
        <SearchInput
          ref="input"
          :placeholder="placeholder"
          v-model="query"
          @keydown.native.enter="submit"
        />
      </div>

      <div class="search-overlay__meta">
        <div class="search-overlay__language">
          <slot name="language" />
        </div>

        <div
          class="search-overlay__close"
          @click="close"
          @keyup.enter="close"
          tabindex="0"
        >
          <CloseIcon />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import homeUrl from "./mixins/homeUrl";

export default {
  mixins: [homeUrl],
  name: "NavigationOverlay",

  props: {
    placeholder: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      show: false,
      query: "",
    };
  },

  methods: {
    open() {
      this.show = true;

      this.$nextTick(() => this.$refs.input.focus());
    },

    close() {
      this.show = false;
    },

    submit() {
      window.location = `${this.homeUrl}search?q=${this.query}`;
    },
  },
};
</script>

<style lang="scss">
@import "../scss/importall.scss";

.search-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  background: #44c4af;
  color: #fff;
  z-index: 200;
  padding: 8rem 3rem 3rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @include mq-tablet {
    padding: 8rem 3rem;
  }
}

.search-overlay__meta {
  position: fixed;
  top: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  color: #000;
}

.search-overlay__close {
  width: 4rem;
  height: 4rem;
  top: 2rem;
  right: 2rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  @include mq-tablet {
    width: 3rem;
    height: 3rem;
  }

  &:hover {
    opacity: 0.6;
  }

  .close-icon {
    fill: #fff;
  }
}
</style>
